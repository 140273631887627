import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { Aside } from './aside.css';
import { gridTemplateColumns, columnOffset } from 'constants/grid';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.gridColumnsMobile || '1fr'};
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  ${({ theme }) => {
    if (theme.verticalAlignment && theme.verticalAlignment == 'center') {
      return `
        align-items: center;
      `;
    }
  }};

  ${({ bgPattern }) => {
    if (bgPattern) {
      return `
        > svg {
          display: none;
        }
      `;
    }
  }};

  ${MEDIA.MIN_DESKTOP`
    grid-template-rows: ${({ theme }) => theme.gridRows};
    grid-template-columns:  ${({ theme }) =>
      theme.gridColumns || gridTemplateColumns.col11}; 

    ${({ bgPattern }) => {
      if (bgPattern) {
        return `
          position: relative;
          overflow: hidden;
          z-index: 0;

          > svg {
            display: block;
            position: absolute;
            bottom: 10%;

            &:first-child {
              left -2%;
            }

            &:last-child {
              right: -2%;
              bottom: 0;
            }
          }
        `;
      }
    }};
  `};

  a {
    align-self: flex-start;

    ${MEDIA.MIN_TABLET`
      width: calc(60% - 10px);
    `};
  }

  .offset {
    ${MEDIA.MIN_TABLET`
      margin-left: ${columnOffset};
      margin-right: ${columnOffset};
    `};
  }

  .offsetLeft {
    ${MEDIA.MIN_TABLET`
      margin-left: ${({ theme }) => {
        if (theme.gridTheme == 'head') {
          return columnOffset;
        } else {
          return `calc(${columnOffset} * 2)`;
        }
      }};
    `};

    ${MEDIA.MIN_DESKTOP`
      margin-left: ${columnOffset};
    `};
  }

  .offsetLeft-desktop {
    ${MEDIA.MIN_DESKTOP`
      margin-left: ${columnOffset};
    `};
  }

  .offsetRight {
    ${MEDIA.MIN_TABLET`
      margin-right: ${({ theme }) => {
        if (theme.gridTheme == 'head') {
          return columnOffset;
        } else {
          return `calc(${columnOffset} * 2)`;
        }
      }};
    `};

    ${MEDIA.MIN_DESKTOP`
      margin-right: ${columnOffset};
    `};
  }

  .offsetRight-tablet {
    ${MEDIA.MIN_TABLET`
      ${MEDIA.DESKTOP`
        margin-right: ${({ theme }) => {
          if (theme.gridTheme == 'head') {
            return columnOffset;
          } else {
            return `calc(${columnOffset} * 2)`;
          }
        }};
      `};
    `};
  }

  ${Aside} {
    ${({ theme, children }) => {
      if (theme.gridTheme == 'head') {
        return MEDIA.MIN_DESKTOP`
          grid-area: 1 / 2 / ${children.length} / 3;
        `;
      } else {
        return MEDIA.MIN_DESKTOP`
          grid-area: ${() => {
            switch (theme.asideAlign) {
              case 'left':
                return `1 / 1 / ${children.length} / 2`;
              default:
                return `1 / 2 / ${children.length} / 3`;
            }
          }};
        `;
      }
    }};
  }
`;
