import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './title.css';

const Title = ({
  children,
  as = 'span',
  className,
  size = 'medium',
  color,
  bordered = false,
  id,
}) => {
  return (
    <Text
      as={as}
      className={className}
      styleSize={size}
      styleColor={color}
      bordered={bordered}
      dangerouslySetInnerHTML={{ __html: children }}
      id={id}
    />
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf([
    'lowercase',
    'extrasmall',
    'small',
    'medium',
    'large',
    'extralarge',
  ]),
  color: PropTypes.oneOf(['green', 'darkGreen', 'blue', 'pink', 'white']),
  bordered: PropTypes.bool,
  id: PropTypes.string,
};

export default Title;
