export const columnOffset = '75px';

export const gridTemplateColumns = {
  col11: '1fr 1fr',
  col21: '2fr 1fr',
  col31: '3fr 1fr',
  col12: '1fr 2fr',
  col13: '1fr 3fr',
  col75: '7fr 5fr',
  col57: '5fr 7fr',
  col84: '8fr 4fr',
  col48: '4fr 8fr',
};
