import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { columnOffset } from 'constants/grid';

export const Aside = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-self: center;

  ${MEDIA.MIN_TABLET`
    grid-template-columns: 1fr 1fr;
  `};

  ${MEDIA.MIN_DESKTOP`
    grid-template-columns: 1fr 1fr;
    justify-self: ${({ theme }) => {
      switch (theme.align) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
      }
    }};
  `};
`;

export const AsideDesktop = styled(Aside)`
  display: none;

  ${MEDIA.MIN_DESKTOP`
    display: grid;
  `};
`;

export const AsideTablet = styled(Aside)`
  display: none;

  ${MEDIA.MIN_TABLET`
    display: grid;
  `};
`;

export const AsideFull = styled(Aside)`
  ${MEDIA.MIN_TABLET`
    grid-template-columns: 1fr;
  `};
`;

export const AsideWithGraph = styled(Aside)`
  display: flex;
  align-items: center;
  margin-bottom: auto;

  ${MEDIA.MIN_TABLET`
    width: calc(100% - (${columnOffset} * 3));
    margin-top: ${columnOffset};
  `};

  ${MEDIA.MIN_DESKTOP`
    width: calc(100% - (${columnOffset} * 2));
    margin-top: 0;
  `};

  .gatsby-image-wrapper {
    width: 100%;
    margin: ${columnOffset} 10%;

    ${MEDIA.MIN_TABLET`
      margin: ${columnOffset};
      max-width: calc(100% - (${columnOffset} * 2));
    `};
  }

  svg {
    position: absolute;

    &:first-child {
      top: 0;
      left: 0;
    }

    &:nth-child(3) {
      top: 60%;
    }

    &:last-child {
      bottom: 0;
      right: 0;
      z-index: -1;
    }
  }
`;

export const AsideWithList = styled(Aside)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: auto;

  ${MEDIA.MIN_TABLET`
    margin-top: ${columnOffset};
  `};

  ${MEDIA.MIN_DESKTOP`
    margin-top: 0;
  `};

  ul {
    width: 60%;

    ${MEDIA.MIN_TABLET`
      width: 50%;
    `};
  }

  .gatsby-image-wrapper {
    width: 25%;
    margin: ${columnOffset} 0 ${columnOffset} 15%;
  }

  svg {
    position: absolute;

    &:first-of-type {
      top: 0;
      right: -10%;
    }

    &:nth-of-type(2) {
      right: 0;
      top: 50%;
    }

    &:last-of-type {
      width: 16%;
      top: 20%;
      left: 50%;
    }
  }
`;

export const AsideWithTwoImages = styled(Aside)`
  display: flex;
  align-items: center;
  margin-bottom: auto;
  padding-bottom: ${columnOffset};

  .gatsby-image-wrapper {
    position: relative;
    width: 50%;

    &:last-of-type {
      margin-left: -10%;
      margin-top: calc(${columnOffset} * 3);
      z-index: -1;
    }
  }

  svg {
    position: absolute;
    z-index: 2;

    &:first-child {
      top: 0;
      left: ${columnOffset};
    }

    &:nth-child(4) {
      width: 30%;
      right: 0;
      bottom: 15%;
    }

    &:last-child {
      bottom: 0;
      left: 25%;
      z-index: -1;
      mix-blend-mode: multiply;
    }
  }
`;

export const AsideProfileImage = styled(Aside)`
  padding: 0 calc(${columnOffset} / 2);
  grid-template-rows: auto 100vw auto;
  justify-items: center;

  ${MEDIA.MIN_TABLET`
    padding: 0;
    grid-template-columns: 1fr 20px 1fr;
    grid-template-rows: auto 150px auto;
    margin-top: ${columnOffset};
    width: calc(100% - (${columnOffset} * 2));
  `};

  ${MEDIA.MIN_DESKTOP`
    margin-top: 0;
    width: calc(100% - ${columnOffset});
  `};

  span {
    padding: 10px 0;
    text-align: center;

    &:nth-child(2) {
      grid-area: 1 / 1 / 2 / 2;

      ${MEDIA.MIN_TABLET`
        grid-area: 1 / 1 / 2 / 2;
      `};
    }

    &:nth-child(4) {
      ${MEDIA.MIN_TABLET`
        grid-area: 3 / 3 / 4 / 4;
      `};
    }
  }

  div {
    &:nth-child(1) {
      justify-self: flex-start;
      align-self: flex-start;

      ${MEDIA.MIN_TABLET`
        justify-self: center;
        grid-area: 2 / 1 / 4 / 2;
      `};

      ${MEDIA.MIN_DESKTOP`
        justify-self: flex-start;
      `};
    }

    &:nth-child(3) {
      align-self: flex-end;
      justify-self: flex-end;

      ${MEDIA.MIN_TABLET`
        justify-self: center;
        grid-area: 1 / 3 / 3 / 4;
      `};

      ${MEDIA.MIN_DESKTOP`
        justify-self: flex-end;
      `};
    }
  }
`;

export const AsideInBgSection = styled(Aside)`
  display: flex;
  flex-direction: column;

  .gatsby-image-wrapper {
    width: 40%;
    margin-top: -100px;
    align-self: center;

    ${MEDIA.MIN_DESKTOP`
      width: 60%;
      margin-top: -50px;
    `};
  }

  svg {
    &:nth-child(1) {
      align-self: flex-end;
    }
  }
`;

export const AsideContacts = styled(Aside)`
  display: flex;
  justify-content: center;

  .gatsby-image-wrapper {
    width: 40%;
  }

  svg {
    position: absolute;

    &:nth-child(1) {
      right: ${columnOffset};
      z-index: -1;
    }

    &:nth-child(2) {
      left: ${columnOffset};
      top: 40px;
      mix-blend-mode: multiply;
      z-index: 2;
    }
  }
`;

export const AsideHomeMain = styled(Aside)`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  min-height: 380px;

  svg {
    position: absolute;
    top: 0;
    right: 0;

    &:first-child {
      left: 0;
      top: auto;
      bottom: 10%;
    }
  }

  .gatsby-image-wrapper {
    position: absolute !important;

    &:nth-of-type(1) {
      width: 50%;
      left: 10%;

      ${MEDIA.MIN_TABLET`
        width: 40%;
      `};

      ${MEDIA.MIN_DESKTOP`
        width: 50%;
      `};
    }

    &:nth-of-type(2) {
      width: 30%;
      right: 0;
    }

    &:last-child {
      width: 15%;
    }

    &:nth-of-type(1),
    &:last-child {
      bottom: 0;
    }

    &:last-child {
      right: 20%;
    }
  }
`;
