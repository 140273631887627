import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import COLORS from 'constants/colors';

export const Text = styled.span`
  display: block;
  word-break: break-word;
  color: ${({ styleColor }) => COLORS[styleColor]};
  font-family: ${({ styleSize }) => {
    if (styleSize !== 'lowercase') {
      return 'balboaplus-fill, sans-serif';
    }
  }};
  font-weight: ${({ styleSize }) => {
    if (styleSize == 'lowercase') {
      return 'bold';
    } else {
      return 'normal';
    }
  }};

  font-size: ${({ styleSize }) => {
    switch (styleSize) {
      case 'lowercase':
        return '20px';
      case 'extrasmall':
        return '32px';
      case 'small':
        return '32px';
      case 'medium':
        return '48px';
      case 'large':
        return '64px';
      case 'extralarge':
        return '48px';
    }
  }};
  line-height: 1;

  ${({ bordered, styleColor }) => {
    if (bordered) {
      return `
        align-self: self-start;
        text-align: center;
        padding: 34px 0 17px;
        border-top: 4px solid ${COLORS[styleColor]};
        border-bottom: 4px solid ${COLORS[styleColor]}; 
      `;
    }
  }}};

  ${MEDIA.MIN_TABLET`
    font-size: ${({ styleSize }) => {
      switch (styleSize) {
        case 'small':
          return '48px';
        case 'medium':
          return '64px';
        case 'large':
          return '80px';
        case 'extralarge':
          return '104px';
      }
    }};

    ${({ bordered }) => {
      if (bordered) {
        return `
          max-width: 435px;
          justify-self: center;
        `;
      }
    }}};
  `};

  ${MEDIA.MIN_DESKTOP`
    ${({ bordered }) => {
      if (bordered) {
        return `
          margin-top: 30px;
          justify-self: flex-start;
      `;
      }
    }}};
  `}
`;
