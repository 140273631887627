import React from 'react';
import PropTypes from 'prop-types';
import TLink from 'components/transitionlink';
import { ThemeProvider } from 'styled-components';
import Icon from 'components/icon';
import { Cta } from './ctabutton.css';

const CtaButton = ({
  children,
  className,
  style = 'primary',
  fontWeight = 'bold',
  icon = '',
  linkTo,
  href,
  target,
  asComponent = TLink,
}) => {
  if (typeof children == 'undefined') return null;

  return (
    <ThemeProvider theme={{ styleType: style, styleFont: fontWeight }}>
      <Cta
        as={asComponent}
        to={linkTo}
        href={href}
        target={target}
        className={className}
      >
        <span>{children}</span>
        <Icon className="icon" type={icon} />
      </Cta>
    </ThemeProvider>
  );
};

CtaButton.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.oneOf([
    'basic',
    'primary',
    'primary-dark',
    'primary-green',
    'rounded',
    'rounded-secondary',
  ]),
  icon: PropTypes.string,
  fontWeight: PropTypes.oneOf(['normal', 'bold']),
  linkTo: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  asComponent: PropTypes.node,
};

export default CtaButton;
