import React from 'react';
import PropTypes from 'prop-types';
import Section from 'components/section';
import { ThemeProvider } from 'styled-components';
import { Container } from 'components/section-main/section-main.css';

const SectionMain = props => (
  <ThemeProvider theme={props.theme || {}}>
    <Section as={Container} {...props} />
  </ThemeProvider>
);

SectionMain.propTypes = {
  theme: PropTypes.object,
};

export default SectionMain;
