import React from 'react';
import PropTypes from 'prop-types';
import SectionMain from 'components/section-main';
import Title from 'components/title';
import { AsideDesktop } from 'components/section-main/aside.css';
import Graph from 'images/pattern-rounded.svg';
import { gridTemplateColumns } from 'constants/grid';

const SectionMainHead = ({ data }) => (
  <SectionMain
    theme={{ gridTheme: 'head', gridColumns: gridTemplateColumns.col21 }}
  >
    <Title as="h2" color="blue" className="offsetLeft-desktop">
      {data.title}
    </Title>

    <Title
      as="h3"
      color="darkGreen"
      size="extrasmall"
      className="offsetLeft-desktop offsetRight"
    >
      {data.content ? data.content.childMarkdownRemark.rawMarkdownBody : ''}
    </Title>

    <AsideDesktop>
      <Graph />
    </AsideDesktop>
  </SectionMain>
);

SectionMainHead.propTypes = {
  data: PropTypes.object,
};

export default SectionMainHead;
