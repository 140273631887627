import styled from 'styled-components';
import COLORS from 'constants/colors';

const INNER_SPACING = 25;
const TRANSITION_TIMING = 333;

export const Cta = styled.button`
  && {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    font-size: 20px;
    font-weight: ${props => {
      if (props.theme.styleFont == 'bold') {
        return 'bold';
      } else {
        return 'normal';
      }
    }};

    background: ${props => {
      switch (props.theme.styleType) {
        case 'basic':
          return 'transparent';
        case 'primary-dark':
          return COLORS.black;
        case 'primary-green':
          return COLORS.darkGreen;
        case 'rounded':
        case 'rounded-secondary':
          return COLORS.gray;
        default:
          return COLORS.white;
      }
    }};

    color: ${props => {
      switch (props.theme.styleType) {
        case 'primary-dark':
        case 'primary-green':
        case 'basic':
          return COLORS.white;
        case 'rounded':
        case 'rounded-secondary':
          return COLORS.blue;
        default:
          return COLORS.ultradarkGray;
      }
    }};

    ${props => {
      if (props.theme.styleType !== 'basic') {
        let bgColorHover = COLORS.darkGreen;
        let textColorHover = COLORS.white;

        if (
          props.theme.styleType == 'primary-dark' ||
          props.theme.styleType == 'primary-green'
        ) {
          bgColorHover = COLORS.white;
          textColorHover = COLORS.darkGreen;
        }

        if (props.theme.styleType == 'rounded') {
          bgColorHover = COLORS.blue;
        }

        if (props.theme.styleType == 'rounded-secondary') {
          bgColorHover = COLORS.darkGreen;
        }

        return `
          min-height: 60px;
          min-width: 200px;
          padding: ${INNER_SPACING / 2}px ${INNER_SPACING}px;
          margin: 0;
          position: relative;
          overflow: hidden;
          transform: scale3d(1.001, 1.001, 1);
          transform-origin: 50% 50%;
          transition: transform ${TRANSITION_TIMING / 2}ms;
          will-change: transform;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) scale3d(0.001, 0.001, 1);
            transition: transform ${TRANSITION_TIMING}ms 0ms;
            will-change: transform;
            width: 125%;
            padding-bottom: 125%;
            border-radius: 50%;
            z-index: 0;
            background: ${bgColorHover};
          }

          span {
            z-index: 1;
            transition: color ${TRANSITION_TIMING / 3}ms ${TRANSITION_TIMING /
          2}ms;
          }

          svg {
            fill: ${COLORS.ultradarkGray};
          }

          &:hover,
          &:focus {
            transform: scale3d(1.02, 1.02, 1);

            &:before {
              transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
            }

            span {
              color: ${textColorHover};
              transition-delay: 0ms;

              rect, circle {
                stroke: ${textColorHover};
              }
            }
          }
        `;
      } else {
        return `
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding: 20px;
          justify-content: flex-start;
          align-items: flex-end;
          background-color: rgba(${COLORS.blackRGB}, 0.01);
          will-change: background-color;
          transiton: background-color ${TRANSITION_TIMING}ms ease;

          &:hover,
          &:focus {
            background-color: rgba(${COLORS.blackRGB}, 0.05);
          }
        `;
      }
    }}

    ${props => {
      if (
        props.theme.styleType.indexOf('primary') > -1 ||
        props.theme.styleType == 'basic'
      ) {
        let arrowColor = COLORS.darkGreen;
        let arrowHoverColor = COLORS.white;
        let arrowMargin = `margin-left: ${INNER_SPACING}px`;

        if (props.theme.styleType == 'basic') {
          arrowColor = COLORS.white;
          arrowMargin = 'margin: 0 0 12px 40px';
        }

        if (
          props.theme.styleType == 'primary-dark' ||
          props.theme.styleType == 'primary-green'
        ) {
          arrowColor = COLORS.white;
          arrowHoverColor = COLORS.darkGreen;
        }

        return `
          &:after {
            content: '';
            width: 0;
            height: 0;
            z-index: 1;           
            border-style: solid;
            border-width: 6px 0 6px 8px;
            border-color: transparent transparent transparent ${arrowColor};
            transition: border-color ${TRANSITION_TIMING /
              3}ms ${TRANSITION_TIMING / 2}ms;
            ${arrowMargin};
          }

          &:hover,
          &:focus {
            &:after {
              border-color: transparent transparent transparent ${arrowHoverColor};
              transition-delay: 0ms;
            }
          }
        `;
      }

      if (props.theme.styleType.indexOf('rounded') > -1) {
        return `
          border-radius: 30px;
          justify-content: center;

          .icon {
            margin-left: auto;
          }
        `;
      }
    }}
  }
`;

export const SimpleLink = styled.a`
  && {
    display: flex;
    align-items: center;
    color: ${({ color } = 'inherit') => color};
    font-weight: bold;

    &:hover,
    &:focus {
      color: ${({ color }) => (color ? COLORS.blue : null)};
    }

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: ${COLORS.gray};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 8px;
      border-color: transparent transparent transparent currentColor;
      z-index: 1;
      margin-left: 10px;
    }

    > * + * {
      margin-left: 10px;
    }
  }
`;
